import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper, getTextClass } from "../../../utils/Utils";
import { Card } from "reactstrap";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  Button,
  PaginationComponent,
  RSelect
} from "../../../components/Component";
import AmmApi from "../../../ammapi";

const AssetUpdate = ({ match }) => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [assetUpdate, setAssetUpdate] = useState({});
  const [apply2All, setApply2All] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [currentUpdateVersion, setCurrentUpdateVersion] = useState({});
  const [currentUpdateVersionIndex, setCurrentUpdateVersionIndex] = useState(-1);
  const [activeTab, setActiveTab] = useState('general');
  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "running", label: "Running" },
    { value: "failure", label: "Failure" },
    { value: "updated", label: "Updated" },
    { value: "changes_needed", label: "Changes Needed" },
  ];

  const distOptions = [
    { value: "Code Plugin", label: "Code Plugin"},
    { value: "Asset Pack", label: "Asset Pack"},
    { value: "Complete Project", label: "Complete Project"},
    { value: "Other", label: "Other"}
  ];

  const getSelecedOptionValue = (val, opt) => {
    for(let i = 0; i < opt.length; i += 1) {
      if(opt[i].value === val) {
        return opt[i];
      }
    }
  };

  useEffect(() => {
    const ammApi = new AmmApi();
    ammApi.getAssetUpdate(match.params.id)
    .then((result) => {
      setAssetUpdate(result);
    })
    .catch(e => console.error);
    setActiveTab('general');
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const getDownloadCount = () => {
    return assetUpdate.downloads ? assetUpdate.downloads.length : 0;
  }

  const getDownloads = () => {
    return assetUpdate.downloads ? assetUpdate.downloads.map((el, index)=>{
      return (
        <div className="data-item" onClick={() => openDownloadModal(index)}>
          <div className="data-col">
            <span className="data-label">{`Download version: ${el.name}`}</span>
            <span className={`text-${getTextClass(el.status)}`}>{el.status}</span>
          </div>
          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
      );
    }) : [];
  }

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  const [cronCurrentPage, setCronCurrentPage] = useState(1);
  const [cronItemPerPage] = useState(50);
  const [cronTotalDocs, setCronTotalDocs] = useState(0);
  const [cronLogs, setCronLogs] = useState([]);
  const [cronLogsSort, setCronLogsSort] = useState('-startedAt');

  // Change Page
  const paginate = (pageNumber) => { showCronLogs(pageNumber) };

  const showCronLogs = (page) => {
    const pageNumber = page || cronCurrentPage;
    const ammApi = new AmmApi();
    ammApi.getCronLogs((pageNumber - 1) * cronItemPerPage, cronItemPerPage, cronLogsSort)
    .then((result)=>{
      setCronCurrentPage(result.page);
      setCronTotalDocs(result.totalDocs);
      setCronLogs(result.cronlogs.map((el)=>{
        return (
          <div className="timeline">
          <h6 className="timeline-head">{el.name}</h6>
          <ul className="timeline-list">
            <li className="timeline-item" key={el.startedAt}>
              <div className="timeline-status is-outline"></div>
              <div className="timeline-date">
                {new Date(el.startedAt).toDateString()} <Icon name="alarm-alt"></Icon>
              </div>
              <div className="timeline-data">
                <h6 className="timeline-title">{el.name}</h6>
                <div className="timeline-des">
                  <p>Started</p>
                  <span className="time">{new Date(el.startedAt).toTimeString()}</span>
                </div>
              </div>
            </li>
            {el.logs.map((item) => {
              return (
                <li className="timeline-item" key={item}>
                  <div className="timeline-status is-outline"></div>
                  <div className="timeline-date">
                    {new Date(el.startedAt).toDateString()} <Icon name="alarm-alt"></Icon>
                  </div>
                  <div className="timeline-data">
                    <h6 className="timeline-title">{el.name}</h6>
                    <div className="timeline-des">
                      <p>{item}</p>
                      <span className="time">{new Date(el.startedAt).toTimeString()}</span>
                    </div>
                  </div>
                </li>
              );
            })}
            <li className="timeline-item" key={el.finishedAt}>
              <div className="timeline-status is-outline"></div>
              <div className="timeline-date">
                {new Date(el.finishedAt).toDateString()} <Icon name="alarm-alt"></Icon>
              </div>
              <div className="timeline-data">
                <h6 className="timeline-title">{el.name}</h6>
                <div className="timeline-des">
                  <p>Finished</p>
                  <span className="time">{new Date(el.finishedAt).toTimeString()}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        );
      }))
    })
    .catch(e => console.error);
  }

  const onInputChange = (e) => {
    if(e.target.name === 'distribution') {
      setAssetUpdate((prev) => {
        return {...prev, [e.target.name]: e.target.value};
      });
      return;
    }
    const updateData = e ? { ...currentUpdateVersion, [e.target.name]: e.target.value } : currentUpdateVersion;
    setAssetUpdate((prev) => {
      for (let i = 0; i < prev.downloads.length; i += 1) {
        if (apply2All || (currentUpdateVersionIndex === i)) {
          if(e) {
            prev.downloads[i] = {...updateData, name: prev.downloads[i].name};
          } else {
            prev.downloads[i] = {...updateData, name: prev.downloads[i].name};
          }
        }
      }
      return prev;
    });
  };

  const openDownloadModal = (index) => {
    setModal(true);
    setCurrentUpdateVersionIndex(index);
    setCurrentUpdateVersion(assetUpdate.downloads[index]);
  };

  const submitForm = () => {
    const ammApi = new AmmApi();
    ammApi.setAssetUpdate(assetUpdate.storeProductId, assetUpdate)
    .catch((result) => {

    })
    .catch(e=>console.error);
    setModal(false);
  };

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar text={findUpper(assetUpdate.name)} theme="primary" />
                    <div className="user-info">
                      <span className="lead-text">{assetUpdate.name}</span>
                      <span className="sub-text">Publisher: {assetUpdate.publisherEmail}</span>
                      <span className="sub-text">Store Product Id: {assetUpdate.storeProductId}</span>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <div className="user-account-info py-0">
                    <h6 className="overline-title-alt">Download Versions</h6>
                    <div className="user-balance">
                      {getDownloadCount()}<small className="currency currency-btc"> Versions</small>
                    </div>
                    <div className="user-balance-sub">
                      Status{" "}
                      <span className={`text-${getTextClass(assetUpdate.status)}`}>{assetUpdate.status}</span>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => { updateSm(false); setActiveTab('general'); }}>
                      <Link
                      to={'#'}
                        className={activeTab === 'general' ? "active" : ""}
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>General</span>
                      </Link>
                    </li>
                    <li onClick={() => { updateSm(false); setActiveTab('history'); }}>
                      <Link
                      to={'#'}
                        className={activeTab === 'history' ? "active" : ""}
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Asset Update History</span>
                      </Link>
                    </li>
                    <li onClick={() => { updateSm(false); setActiveTab('logs'); showCronLogs(); }}>
                      <Link
                        to={'#'}
                        className={activeTab === 'logs' ? "active" : ""}
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Cron Logs</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`card-inner card-inner-lg ${activeTab === 'general' ? '' : 'd-none'}`}>
              <Head title="Asset Update - Detail"></Head>
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">General - Asset Update</BlockTitle>
                    <BlockDes>
                      <p>General Info of the Asset Update for {assetUpdate.name}</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Basics</h6>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Name</span>
                      <span className="data-value">{assetUpdate.name}</span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Store Link</span>
                      <span className="data-value"><a href={assetUpdate.storeLink} rel="noreferrer" target="_blank">{assetUpdate.storeLink}</a></span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Publisher Email</span>
                      <span className="data-value">{assetUpdate.publisherEmail}</span>
                    </div>
                  </div>
                  <div className="data-item">
                    <div className="data-col">
                      <span className="data-label">Distribution Method</span>
                      <span className="data-value">{assetUpdate.distribution}</span>
                    </div>
                  </div>
                </div>
              </Block>

              <Block>
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Downloads</h6>
                  </div>
                  {getDownloads()}
                </div>
              </Block>

              <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
                <ModalBody>
                  <a
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModal(false);
                    }}
                    className="close"
                  >
                    <Icon name="cross-sm"></Icon>
                  </a>
                  <div className="p-2">
                    <h5 className="title">Update Downloads</h5>
                    <span className="sub-text">{`Changes will be applied to ${apply2All ? 'ALL update versions of ' :  'this update version of '}the asset immediately`}</span>
                    <span className="sub-text">But changes are only sent to the server after click 'Update Downloads'</span>
                    <ul className="nk-nav nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${modalTab === "1" && "active"}`}
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModalTab("1");
                          }}
                          href="#personal"
                        >
                          Download
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                        <Row className="gy-4">
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="name">
                                Version/Name
                              </label>
                              <input
                                type="text"
                                id="name"
                                className="form-control"
                                name="name"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={currentUpdateVersion.name}
                                placeholder="Enter version/name"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="file">
                                {`Publisher provided link (file) `}
                                <a href={currentUpdateVersion.file} rel="noreferrer" target="_blank" title={currentUpdateVersion.file}>
                                  <Icon name="download"></Icon>
                                </a>
                              </label>
                              <input
                                disabled
                                type="text"
                                id="file"
                                className="form-control"
                                name="file"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={currentUpdateVersion.file}
                                title={currentUpdateVersion.file}
                                placeholder="Publisher provided link"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="file_internal">
                                Objectstore Link (file_internal)
                              </label>
                              <input
                                type="text"
                                id="file_internal"
                                className="form-control"
                                name="file_internal"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={currentUpdateVersion.file_internal}
                                title={currentUpdateVersion.file_internal}
                                placeholder="Objectstore Link"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="updated_at">
                                Updated At
                              </label>
                              <DatePicker
                                disabled
                                id="updated_at"
                                name="updated_at"
                                selected={new Date(currentUpdateVersion.updated_at)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="notes">
                                Notes
                              </label>
                              <input
                                type="text"
                                id="notes"
                                className="form-control"
                                name="notes"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={currentUpdateVersion.notes}
                                placeholder="Notes"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="error">
                                Error
                              </label>
                              <input
                                type="text"
                                id="error"
                                className="form-control"
                                name="error"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={currentUpdateVersion.error}
                                placeholder="Error"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="status">
                                Status
                              </label>
                              <RSelect 
                                options={statusOptions}
                                defaultValue={getSelecedOptionValue(currentUpdateVersion.status, statusOptions)}
                                name="status"
                                id="status"
                                onChange={(e) => { const ev = { target: { name: 'status', value: e.value } }; onInputChange(ev); }}
                                placeholder="Status"
                              />
                            </FormGroup>
                          </Col>
                          <Col size="6">
                          <FormGroup>
                            <label className="form-label" htmlFor="status">
                              Distribution Method (applies to all)
                            </label>
                            <RSelect 
                              options={distOptions}
                              defaultValue={getSelecedOptionValue(assetUpdate.distribution, distOptions)}
                              name="distribution"
                              id="distribution"
                              onChange={(e) => { const ev = { target: { name: 'distribution', value: e.value } }; onInputChange(ev); }}
                              placeholder="Distribution method"
                              />
                            </FormGroup>
                          </Col>
                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button
                                  color="primary"
                                  size="lg"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    submitForm();
                                  }}
                                >
                                  Update Downloads
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancel
                                </a>
                              </li>
                              <li>
                                <div className="custom-control custom-switch">
                                  <input type="checkbox" className="custom-control-input form-control" checked={apply2All} id="apply2all" onChange={(e) => { setApply2All(e.target.checked); onInputChange(); }} />
                                  <label className="custom-control-label" htmlFor="apply2all">
                                    Apply to all Download Versions{" "}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </div>
            <div className={`card-inner card-inner-lg ${activeTab === 'logs' ? '' : 'd-none'}`}>
              <Head title="Asset Update - Detail"></Head>
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Cron Logs - Asset Update</BlockTitle>
                    <BlockDes>
                      <p>Cron Logs</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <div className="nk-data data-list" key="cron-logs">
                  <div className="data-head">
                    <h6 className="overline-title">Cron Logs</h6>
                  </div>
                  <div className="card-inner-group" key="cron-logs-data">
                    <div className="card-inner overflow-auto">
                      <div className="h-500px">
                      {cronLogs}
                      </div>
                    </div>
                    <div className="card-inner" key="cron-logs-pagination">
                      {cronLogs.length > 0 ? (
                        <PaginationComponent
                          itemPerPage={cronItemPerPage}
                          totalItems={cronTotalDocs}
                          paginate={paginate}
                          currentPage={cronCurrentPage}
                        />
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">No cron logs found</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default AssetUpdate;
