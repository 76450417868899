
class AmmApi {
  url = process.env.REACT_APP_API_URL;

  async login (loginName, passcode) {
    const response = await fetch(`${this.url}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'email': loginName, 'password': passcode }),
    });

    if (!response.ok) {
      throw new Error('Failed to login');
    }

    const resData = await response.json();
    const token = await resData.token;
    const userId = await resData.userId;
    const userName = await resData.userName || loginName;
    
    localStorage.setItem('accessToken', token);
    localStorage.setItem('userId', userId);
    localStorage.setItem('userName', userName);
    localStorage.setItem('userEmail', userName);

    return resData.message;
  }

  async getAssetUpdateList (offset, limit, sort) {
    const token = window.localStorage.getItem("accessToken");
    if(!token) {
      window.location = '/auth-login';
    }
    let query = `offset=${offset}&limit=${limit}&sort=${sort}`;
    const response = await fetch(`${this.url}/admin/assetupdatelist?${query}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if(!response.ok) {
      window.localStorage.removeItem("accessToken");
      window.location = '/auth-login';
    }
    const resData = await response.json();
    return resData;
  }

  async getAssetUpdate(id) {
    const token = window.localStorage.getItem("accessToken");
    if(!token) {
      window.location = '/auth-login';
    }
    const response = await fetch(`${this.url}/admin/assetupdate/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    if(!response.ok) {
      window.localStorage.removeItem("accessToken");
      window.location = '/auth-login';
    }
    const resData = await response.json();
    return resData;
  }

  async setAssetUpdate (id, submitData) {
    const token = window.localStorage.getItem("accessToken");
    if(!token) {
      window.location = '/auth-login';
    }
    const response = await fetch(`${this.url}/admin/assetupdate/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(submitData),
    });
    
    if(!response.ok) {
      window.localStorage.removeItem("accessToken");
      window.location = '/auth-login';
    }
    const resData = await response.json();
    return resData;
  }

  async getAssetUpdateHistory (offset, limit, sort) {
    const token = window.localStorage.getItem("accessToken");
    if(!token) {
      window.location = '/auth-login';
    }
    let query = `offset=${offset}&limit=${limit}&sort=${sort}`;
    const response = await fetch(`${this.url}/admin/assetupdatehistory?${query}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if(!response.ok) {
      window.localStorage.removeItem("accessToken");
      window.location = '/auth-login';
    }
    const resData = await response.json();
    return resData;
  }

  async getCronLogs (offset, limit, sort) {
    const token = window.localStorage.getItem("accessToken");
    if(!token) {
      window.location = '/auth-login';
    }
    let query = `offset=${offset}&limit=${limit}&sort=${sort}`;
    const response = await fetch(`${this.url}/admin/cronlogs?${query}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if(!response.ok) {
      window.localStorage.removeItem("accessToken");
      window.location = '/auth-login';
    }
    const resData = await response.json();
    return resData;
  }
}

export default AmmApi;
